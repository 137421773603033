import React from 'react';
import '../styles/flames.css';

export default function Flames() {
    return (
        <>
            <img src={'./flames.png'} alt="flames" className="flames" />
            <img src={'./flames.png'} alt="flames" className="flames" />
        </>
    );
}